export const activityCategoryColour: Record<string, string> = {
  tokenomics_and_protocol: 'bg-[#BB8C23]',
  go_to_market_partnerships: 'bg-[#254699]',
  legal_and_compliance_post_tge: 'bg-[#A1C490]',
  legal_and_compliance_pre_tge: 'bg-[#A1C490]',
  finance_and_fundraising_post_tge: 'bg-[#3F6E3B]',
  finance_and_fundraising_pre_tge: 'bg-[#3F6E3B]',
  launch_checklist: 'bg-[#C1A9C6]',
  analysis_and_insights: 'bg-[#BB8C23]',
  performance_optimization: 'bg-[#254699]',
  strategic_partnerships: 'bg-[#A1C490]',
}

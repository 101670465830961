<script lang="ts" setup>
import type GoogleSheetsConfirmModal from '#core/components/container/GoogleSheetsConfirmModal.vue'
import { activityCategoryColour } from '../utils/transforms'

const auth = useAuth()
const { project } = storeToRefs(auth)

const tracer = useTracer()
const activityStore = usePlaybookActivitiesStore()
const { selectedActivity, activities } = storeToRefs(activityStore)
const { setSelectedActivity, setActivityStatus } = activityStore

const googleSheetsConfirmModal = ref<InstanceType<typeof GoogleSheetsConfirmModal> | null>()

interface ActivityDependency {
  id: string
  title: string
}

const activityDependencies = computed(() => {
  if (!selectedActivity.value || !activities.value || !selectedActivity.value.activity.dependsOnActivityId) {
    return []
  }

  const result: ActivityDependency[] = []
  const seen = new Set<string>()

  function addDependency(activityId: string) {
    if (seen.has(activityId) || !activities.value) {
      return
    }
    seen.add(activityId)

    for (const category of activities.value.advanced) {
      if (category.activityId === activityId) {
        result.push({
          id: category.activityId,
          title: category.title,
        })
        // Don't return here - need to check activities within categories too
      }

      for (const activity of category.activities) {
        if (activity.id === activityId) {
          result.push({
            id: activity.id,
            title: activity.title,
          })

          if (activity.dependsOnActivityId) {
            addDependency(activity.dependsOnActivityId)
          }
          // Found the activity, but don't return - need to check other categories
          break
        }
      }
    }
  }

  addDependency(selectedActivity.value.activity.dependsOnActivityId)
  return result
})

async function handleOpenTool() {
  if (!selectedActivity.value) {
    return
  }

  const { activity } = selectedActivity.value
  if (!activity.toolUrl) {
    return
  }

  if (activity.toolType === 'internal') {
    navigateTo(activity.toolUrl)
    tracer.custom('activity_open_tool', { toolId: activity.id })
    return
  }

  if (activity.toolUrl.startsWith('https://docs.google.com/spreadsheets')) {
    const confirm = await googleSheetsConfirmModal.value?.confirm()
    if (!confirm) {
      return
    }
  }

  tracer.custom('activity_open_tool', { toolId: activity.id })
  window.open(activity.toolUrl, '_blank')
}
</script>

<template>
  <USlideover
    :model-value="!!selectedActivity" class="z-10" :ui="{ width: 'max-w-[560px] min-w-[500px]' }"
    @update:model-value="setSelectedActivity(null)"
  >
    <div class="flex-1 side-panel border-l border-forgd-bgd-600 bg-forgd-bgd-100 font-display overflow-y-scroll">
      <div
        class="h-[70px] px-5 flex items-center justify-between border-b border-forgd-bgd-600 sticky top-0 bg-forgd-bgd-100 z-50"
      >
        <div class="text-sm/[1.5] font-semibold">
          Activity Details
        </div>
        <UiButton size="sm" color="white" variant="ghost" icon="i-heroicons-x-mark" @click="setSelectedActivity(null)" />
      </div>
      <div v-if="selectedActivity" class="flex flex-col gap-5 p-4">
        <div class="flex justify-start gap-3">
          <div class="w-1 min-h-full rounded" :class="activityCategoryColour[selectedActivity.category.activityId]" />
          <div class="flex flex-col items-start justify-start">
            <div class="truncate text-forgd-primary-900 text-sm">
              Category: {{ selectedActivity.category.title }}
            </div>
            <div class="text-forgd-primary-900 text-base font-semibold">
              {{ selectedActivity.activity.title }}
            </div>
          </div>
        </div>
        <div class="text-xs text-forgd-gray-600 bg-forgd-bgd-300 rounded-xl p-4 flex items-center justify-between">
          <div class="flex items-center gap-1">
            <template v-if="project?.tokenListed">
              <div>Operation type:</div>
              <DashboardBadgeOperationType :model-value="selectedActivity.activity.executionType" />
            </template>
            <template v-else>
              <div>Estimated duration:</div>
              <DashboardBadgeDuration :model-value="selectedActivity.activity.durationEstimateDays" />
            </template>
          </div>
          <div class="flex items-center gap-1">
            <template v-if="project?.tokenListed">
              <div>Recommended frequency:</div>
              <DashboardBadgeFrequency :model-value="selectedActivity.activity.reviewInterval" />
            </template>
            <template v-else>
              <div>Activity status:</div>
              <DashboardActivityStatus
                :model-value="selectedActivity?.activity.status || 'not_started'"
                @update:model-value="e => setActivityStatus(selectedActivity!.activity.id, e)"
              />
            </template>
          </div>
        </div>
        <UiAlert
          v-if="!project?.tokenListed && activityDependencies.length > 0"
          title="This activity has dependencies"
          type="info"
        >
          <div class="space-y-4">
            <div class="text-sm text-forgd-gray-600">
              We recommend completing the following activities first before you engage with this one:
            </div>
            <ul class="list-[square] pl-5">
              <li v-for="dependency in activityDependencies" :key="dependency.id">
                {{ dependency.title }}
              </li>
            </ul>
          </div>
        </UiAlert>
        <NuxtLink
          v-if="selectedActivity.activity.toolUrl"
          :to="selectedActivity.activity.toolUrl"
          @click.prevent
        >
          <UiButton variant="solid" class="w-full" @click.prevent="handleOpenTool()">
            Open Tool
          </UiButton>
        </NuxtLink>
        <UDivider />
        <div v-if="selectedActivity.activity.resourceUrl" class="prose prose-sm prose-gray">
          <AcademySnippetLoader
            disable-headline-link
            :path="`/_playbook${selectedActivity.activity.resourceUrl}`"
          />
        </div>
      </div>
    </div>
    <GoogleSheetsConfirmModal ref="googleSheetsConfirmModal" />
  </USlideover>
</template>
